.accordion {
  width: 100%;
}

.accordion .container {
  position: relative;
  margin: 5px 10px;
}

.accordion .label {
  position: relative;
  padding: 20px;
  cursor: pointer;
  letter-spacing: 1px;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  background-color: #ffff;
}

.accordion .label:hover {
  background: linear-gradient(180deg, #f26a21 0%, #ed2124 100%);
  color: #ffff;
}

.accordion i {
  padding-right: 2rem;
  color: #f26a21;
  transition: all 0.3s ease;
}

.accordion .label:hover i {
  color: #ffff;
}

.accordion .label::before {
  content: "+";
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 1.8rem;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
}

.accordion .container.active .label::before {
  content: "-";
  font-size: 1.8rem;
  transform: translateY(-50%) rotate(180deg);
}

.accordion .content {
  position: relative;
  max-height: 0;
  text-align: justify;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.accordion .content p {
  line-height: 25px;
}

.accordion .content ol {
  list-style-type: decimal;
}

.accordion .content .details {
  background-color: #ffff;
  padding: 2rem 3rem;
  opacity: 0.9;
}

.accordion .container.active .content {
  padding: 3px 2px;
  max-height: 5000px;
}

.accordion ul {
  list-style: circle;
  padding-inline-start: 40px;
}

.accordion .inside-list ul {
  list-style: square;
}

.accordion .inside-list i {
  color: #161617;
}

@media screen and (max-width: 480px) {
  .accordion ul {
    padding-inline-start: 20px;
  }

  .accordion .content .details {
    padding: 1rem 2rem 2rem 1rem;
    font-size: 0.9rem;
  }

  .accordion .content p {
    line-height: 20px;
  }

  .inside-list ul {
    padding-inline-start: 15px;
  }
}

@media screen and (max-width: 375px) {
  .accordion .container {
    margin: 5px;
  }

  .accordion i {
    padding-right: 1rem;
  }

  .accordion .label {
    padding: 1rem;
  }

  .accordion .label::before {
    right: 15px;
    font-size: 1.5rem;
  }
}
