#location {
  background-color: #f2f2f2;
}

.location-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: center;
  gap: 1rem;
}

.location-container img {
  max-width: 100%;
  height: auto;
}

.location-wrapper .location-container {
  width: 50%;
}

.location-container:nth-child(1) {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.location-container:nth-child(1) p {
  margin: 0;
}

.location-container:nth-child(2) {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .location-wrapper .location-container {
    width: 100%;
  }

  .location-container:nth-child(2) {
    display: none;
  }
}
