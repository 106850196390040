.textReg {
  font-family: "PoppinsReg", sans-serif;
}

.textMed {
  font-family: "PoppinsMed", sans-serif;
}

.textSemiBold {
  font-family: "PoppinsSemiBold", sans-serif;
}

.textBold {
  font-family: "PoppinsBold", sans-serif;
}

@font-face {
  font-family: "PoppinsReg";
  src: url(../fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsMed";
  src: url(../fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: url(../fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../fonts/Poppins-Bold.ttf) format("truetype");
}
