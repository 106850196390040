#contacts {
  background-color: #ffff;
  height: auto;
  margin-bottom: 2rem;
}

.contacts-wrapper {
  display: flex;
  flex-direction: row;
  height: auto;
  gap: 1rem;
}

.contacts-wrapper .contacts-container {
  width: 50%;
}

.contacts-container:nth-child(1) {
  display: flex;
  align-items: center;
}

.contacts-container img {
  max-width: 100%;
  height: auto;
}

.contacts-container:nth-child(2) {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contacts-container:nth-child(2) p {
  margin: 0;
}

.viber-link-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 0px;
  border-radius: 25px;
  background-color: #7360f2;
  width: 50%;
  color: #ffff;
}

.viber-link-container i {
  font-size: 30px;
}

@media screen and (max-width: 768px) {
  .contacts-wrapper .contacts-container {
    width: 100%;
  }

  .contacts-container:nth-child(1) {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .viber-link-container {
    width: 100%;
  }
}
