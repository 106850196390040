.discounted-wrapper {
  display: flex;
  padding: 3rem 5rem;
  flex-direction: column;
  align-items: center;
}

.discounted-wrapper p {
  margin: 0;
}

.special-offer-container {
  margin: 1rem;
  padding: 10px;
  font-size: 13px;
  text-align: center;
  width: 100%;
  background-color: #f26a21;
  color: white;
  border-radius: 10px;
}

.discounted-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  width: 100%;
}

.discounted-format {
  background-color: #f7f2f2;
  color: #161617;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  align-items: center;
  border-radius: 2rem;
  margin: 5px;
  width: calc(50% - 10px);
  gap: 1rem;
  max-width: 400px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.discounted-format .internet-speed {
  color: #ed2124;
}

.discounted-format .price {
  color: #ffff;
  background-color: #ed2124;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
}

.discounted-format .price p:nth-child(1) {
  font-size: 15px;
  position: relative;
  bottom: -10px;
}

.discounted-format .price p:nth-child(2) {
  font-size: 2.5rem;
  font-family: "PoppinsSemiBold", sans-serif;
}

.discounted-format .price p:nth-child(2) span {
  font-size: 15px;
  font-family: "PoppinsMed", sans-serif;
}

.discounted-format .internet-speed {
  width: 100%;
  text-align: right;
  font-size: 2rem;
  font-family: "PoppinsBold";
}

.discounted-format .internet-speed span {
  font-size: 1rem;
  color: dimgray;
}

.discounted-format .internet-plan span {
  font-size: 1rem;
  color: dimgray;
}

.special-offer-header {
  font-size: 3em;
}

.special-offer-description {
  font-size: 1.5em;
}

@media screen and (max-width: 768px) {
  .discounted-container {
    flex-direction: column; 
    align-items: center; 
  }

  .discounted-format {
    width: 100%; 
    max-width: unset;
  }
  .special-offer-header {
    font-size: 2em;
  }

  .special-offer-description {
    font-size: 1.25em;
  }
}
