#promoPlan {
  height: auto;
}

.promo-wrapper {
  display: flex;
  padding: 3rem 5rem;
}

.promo-container {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.promo-wrapper .left {
  width: 30%;
}

.promo-wrapper .right {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0;
    flex-direction: unset;
}

.promo-container .row {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.promo-container .flying-dog-img {
  max-width: 100%;
  height: auto;
}

.promo-format {
  background-image: linear-gradient(180deg, #F26A21 0%, #ED2124 100%);
  color: #ffff;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  align-items: center;
  border-radius: 2rem;
  justify-content: space-around;
  min-height: 450px;
  margin: 5px;
  width: calc(50% - 15px);
}

.promo-format div {
  text-align: center;
}

.promo-format div img {
  width: 100px;
}

.promo-format p {
  margin: 0;
}

.promo-format div:nth-child(2) p:nth-child(2){
  font-family: "PoppinsMed", sans-serif;
  font-size: 1.8rem;
}

@media screen and (max-width: 768px) {
.promo-wrapper {
  flex-direction: column;
  padding: 0 2rem 0;
}
.promo-wrapper .left,
.promo-wrapper .right {
  width: 100%;
}

.promo-format {
  width: 100%;
}

.promo-container {
  align-items: center;
}

.promo-container .row {
  width: 100%;
}
}


@media screen and (max-width: 480px) {
  #promoPlan {
    height: auto;
  }

  .promo-wrapper {
    min-height: 100vh;
  }

}
