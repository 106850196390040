#about {
  height: auto;
}

.about-wrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.about-wrapper .about-container {
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  padding: 3rem;
  transition: all 0.3s ease;
}

.about-wrapper .left-side {
  width: 50%;
  position: relative;
  bottom: -25px;
  left: 15px;
  z-index: 2;
  background: linear-gradient(180deg, #f26a21, #ed2123);
  border-radius: 25px;
  color: #ffff;
}

.about-container li {
  line-height: 30px;
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

.about-container li i {
  padding-right: 10px;
}

.about-wrapper .right-side {
  width: 60%;
  position: relative;
  top: -25px;
  right: 15px;
  z-index: 3;
  background-color: #ffff;
  border-radius: 0px 25px 25px 0px;
  box-shadow: 0px 3px 60px 0px rgba(0, 0, 0, 0.07);
}

.about-container .bg-right {
  background-image: url(../images/about-right-bg.jpg);
  background-position: center 25%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 230px;
  transition: all 0.3s ease;
}

.about-container .description {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.about-container .description p {
  margin: 0;
  transition: all 0.3s ease;
}

/* animation for right and left div */
.about-wrapper.animation .left-side {
  animation: moveRightToLeft 1s ease-in-out;
}

.about-wrapper.animation .right-side {
  animation: moveLeftToRight 1s ease-in-out;
}

@keyframes moveLeftToRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes moveRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
/*  */

@media screen and (max-width: 1024px) {
  .about-wrapper .about-container {
    padding: 2rem;
  }
}

@media screen and (max-width: 768px) {
  #about {
    height: auto;
  }

  .about-wrapper {
    flex-direction: column;
    min-height: 100vh;
    gap: 1rem;
  }

  .about-wrapper .about-container {
    height: auto;
    padding: 1.5rem;
  }

  .about-wrapper .left-side,
  .about-wrapper .right-side {
    position: unset;
    width: auto;
  }

  .about-wrapper .left-side {
    border-radius: 25px 0 0 25px;
  }

  .about-container li {
    line-height: inherit;
    font-size: 1.1rem;
  }

  .about-container .bg-right {
    min-height: 230px;
  }
}

@media screen and (max-width: 480px) {
  .about-container li,
  .about-container .description p {
    line-height: 25px;
    font-size: 0.9rem;
  }

  .about-container .bg-right {
    height: 180px;
    min-height: auto;
  }
}

@media screen and (max-width: 320px) {
  .about-container .bg-right {
    height: 135px;
  }
}
