.contact-button-container {
  display: flex;
  gap: 1rem;
}

.contact-button {
  min-width: 150px;
  border: 2px solid #f26a21;
  padding: 8px;
  border-radius: 5px;
  font-family: 'PoppinsSemiBold';
  font-size: 12px;
  cursor: pointer;
  color: #f26a21;
  background-color: #ffff;
  opacity: .8;
}

.default-button {
  background-color: #f26a21;
  color: #fff;
  opacity: 1;
}

.existing-customer-button {
  background-color: #f26a21;
  color: #ffff;
  opacity: 1;
}

.contact-button:hover:not(.default-button):not(.existing-customer-button) {
  opacity: 1;
}

