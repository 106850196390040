.custom-select {
  position: relative;
  width: 100%;
}

.selected-option {
  padding: 10px;
  border: 2px solid #f26a21;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  background: #ffff;
  z-index: 2;
}

.selected-option .arrow-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  right: 0;
  transition: transform 0.3s ease;
}

.open .arrow-icon {
  transform: translate(-50%, -50%) rotate(-180deg);
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-top: none;
  opacity: 0;
  pointer-events: none;
  max-height: 0;
}

.option {
  padding: 15px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.option:hover {
  background-color: #e7e7e7;
}

.open .options {
  overflow: auto;
  opacity: 1;
  pointer-events: auto;
  width: 100%;
  max-height: 20vh;
  z-index: 10;
}

.fadeIn {
  animation: fadeIn 0.3s ease;
}

.fadeOut {
  animation: fadeOut 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: 20vh;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
}
