#headline {
  background-image: url(../images/headline-cover.jpg);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
}

.headline-wrapper {
  display: flex;
  height: 90vh;
}

.headline-wrapper .left-side {
  width: 50%;
  background: #ffff;
}

.headline-wrapper div:nth-child(2) {
  background-image: url(../images/headline-cover-overlay.png);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
  width: 50%;
  position: relative;
  top: 0;
  right: 1px;
}

.headline-text {
  width: 70%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.headline-text h1 {
  font-size: clamp(2.2rem, 4vw, 3rem);
  font-family: "PoppinsBold", sans-serif;
  margin: 0;
}

.headline-text span {
  color: #f26a21;
}

.headline-text div {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.5rem;
}

.headline-text div .custom-button {
  text-transform: uppercase;
  padding: 15px 25px;
  letter-spacing: 1px;
  font-family: "PoppinsMed";
}

.headline-text div .custom-button img {
  filter: grayscale(100%) brightness(0) invert(100%);
}

.center-circle {
  background: linear-gradient(180deg, #f26a21 0%, #ed2124 100%);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  text-align: center;
  height: 300px;
  width: 300px;
  padding: 3rem;
}

.center-circle p {
  font-size: 35px;
  color: #ffff;
  word-wrap: break-word;
}

/* mobile view */

@media screen and (max-width: 1024px) {
  #headline {
    background: 0;
  }

  .headline-wrapper .left-side {
    width: 100%;
  }

  .headline-wrapper div:nth-child(2) {
    width: auto;
  }

  .center-circle {
    display: none;
  }

  .headline-text {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .headline-text div {
    flex-direction: column;
  }
}

@media screen and (max-width: 320px) {
  #headline,
  .headline-wrapper,
  .headline-text {
    height: 100vh;
  }

  .headline-text {
    justify-content: flex-start;
  }
}
