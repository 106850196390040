.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 2em;
  padding: 10px 20px;
  letter-spacing: 0.3px;
  min-width: 140px;
  font-family: "PoppinsMed", sans-serif;
}

.custom-button {
  background: linear-gradient(180deg, #f26a21 0%, #ed2124 100%);
  color: #ffff;
}

.outlined-button {
  border: 2px solid #f26a21;
  background-color: #ffff;
  color: #f26a21;
}

.plan-button {
  border: 2px solid #f26a21;
  padding: 8px 30px;
  border-radius: 5px;
  background-color: #f26a21;
  color: #ffff;
  font-family: 'PoppinsSemiBold';
  font-size: 12px;
}

.plan-button:hover {
  background-color: #fff;
  color: #f26a21;
}

.plan-button:disabled {
  opacity: 0.8;
  background-color: #f26a21;
  color: #ffff;
  pointer-events: none;
}

@media screen and (max-width: 375px) {
  .plan-button {
    padding: 5px 25px;
    font-size: 0.9rem;
  }
}
