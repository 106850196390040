.primary-accent-color {
  color: #f26a21;
}

.secondary-accent-color {
  color: #ed2124;
}

.gradient-color {
  background: linear-gradient(180deg, #f26a21 0%, #ed2124 100%);
}
