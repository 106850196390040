/* topDivider */
.wave-top-divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 1;
}

.wave-top-divider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 60px;
}

.wave-top-divider .shape-fill {
  fill: #ffffff;
}

#techInfo .wave-top-divider .shape-fill {
  fill: #f2f2f2;
}

/* bottomDivider */
.wave-bottom-divider {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave-bottom-divider svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 60px;
  transform: rotateY(180deg);
}

.wave-bottom-divider .shape-fill {
  fill: #ffffff;
}

#why .wave-bottom-divider .shape-fill {
  fill: #f2f2f2;
}

/* top opaque divider */
.wave-opaque-top-divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave-opaque-top-divider svg {
  position: relative;
  display: block;
  width: calc(186% + 1.3px);
  height: 100px;
  transform: rotateY(180deg);
}

.wave-opaque-top-divider .shape-fill {
  fill: #ffffff;
}

/* bottom opaque divider */
.wave-opaque-bottom-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.wave-opaque-bottom-divider svg {
  position: relative;
  display: block;
  width: calc(186% + 1.3px);
  height: 100px;
  transform: rotateY(180deg);
}

.wave-opaque-bottom-divider .shape-fill {
  fill: #ffffff;
}

@media screen and (max-width: 480px) {
  /* top opaque divider */
  .wave-opaque-top-divider svg,
  /* bottom opaque divider */
  .wave-opaque-bottom-divider svg {
    height: 70px;
  }
}

@media screen and (max-width: 320px) {
  /* top divider */
  .wave-top-divider svg,
  /* bottom divier */
  .wave-bottom-divider svg {
    height: 30px;
  }

  /* top opaque divider */
  .wave-opaque-top-divider svg,
  /* bottom opaque divider */
  .wave-opaque-bottom-divider svg {
    height: 50px;
  }
}
