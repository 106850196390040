#techInfo {
  height: 90vh;
  position: relative;
  background-image: url(../images/tech-info-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.techInfo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90vh;
  color: #ffff;
  gap: 15px;
}

.techInfo-wrapper ul li i {
  padding-right: 10px;
  letter-spacing: 0.3px;
}

@media screen and (max-width: 480px) {
  #techInfo {
    height: auto;
  }

  .techInfo-wrapper {
    min-height: 100vh;
  }

  .techInfo-wrapper ul li {
    line-height: 25px;
    font-size: 0.9rem;
  }
}
