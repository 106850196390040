#plans {
  height: auto;
  background-color: #ffff;
}

.plans-wrapper h1 {
  margin: 0rem;
  text-align: center;
  padding: 1.1rem;
  font-family: "PoppinsBold", sans-serif;
}

.upgradedPlans-wrapper,
.subscriptionPlans-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.upgradedPlans-container {
  background-color: #f6f6f6;
  margin: 10px;
  width: calc(50% - 20px);
}

.upgradedPlans-container .top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.top .star{
  font-size: 2rem;
  color: #f26a21;
}

.top span {
  font-size: 1.3rem;
  text-align: center;
  font-family: "PoppinsSemibold", sans-serif;
}

.upgradedPlans-container .mid {
  background: linear-gradient(180deg, #f26a21 0%, #ed2124 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffff;
  padding: 1rem 0;
}

.mid div {
  display: flex;
  align-items: baseline;
  font-size: 1.5rem;
}

.mid p {
  margin: 0;
}

.mid div p:nth-child(1) {
  position: relative;
  top: -25px;
}
.mid div p:nth-child(2) {
  font-size: 3rem;
  font-family: "PoppinsMed", sans-serif;
}
.mid div p:nth-child(3) {
  font-size: 1.1rem;
}

.upgradedPlans-container .bot {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #54595f;
  font-size: .9rem;
  padding: 1rem
}

.subscriptionPlans-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.subscriptionPlans-wrapper h1 {
  margin: 0rem;
  text-align: center;
  padding: 1.1rem;
  font-family: "PoppinsBold", sans-serif;
}

.subscriptionPlans-wrapper h2 {
  font-size: clamp(1.6rem, 4vw, 2rem);
  margin: 1rem;
  text-align: center;
  line-height: 30px;
}

.plans {
  background-color: #ffff;
  box-shadow: 0px 0px 23px 0px rgba(87, 45, 3, 0.11);
  margin: 10px;
  width: calc(50% - 20px);
}

.subscriptionPlans-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem 2rem;
  gap: 1rem;
  justify-content: space-between;
  height: 94%;
}

.top-gradient {
  width: 100%;
  background: linear-gradient(180deg, #f26a21, #ed2123);
  padding: 8px;
  text-align: center;
  font-family: "PoppinsMed", sans-serif;
  color: #ffff;
}

.top-gradient p,
.planDetails p  {
  margin: 0;
}

.pricing-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.pricing-box {
  border: 1px solid #f26a21;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  min-width: 100px;
  max-width: 100px;
  box-shadow: #f26a21 1.95px 1.95px 2.6px;
}

.pricing-box p {
  margin: 0;
}

.pricing-box p:nth-child(1) {
  font-family: 'PoppinsSemiBold', sans-serif;
}

.pricing-box p:nth-child(2),
.similiar-features p {
  font-size: .9rem;
}

.features {
  display: flex;
  flex-direction: column;
  font-size: .9rem;
  gap: 10px;
  overflow: auto;
}

.subscriptionPlans-details i {
  margin-right: 5px;
}

.subscriptionPlans-speed {
  display: flex;
  align-items: center;
  height: 50px;
}

.subscriptionPlans-speed p {
  margin: 0;
  height: 50px;
}

.subscriptionPlans-speed p:nth-child(1) {
  display: flex;
  align-items: flex-start;
  font-size: 1.1rem;
  padding-top: 5px;
  color: #f26a21;
}

.subscriptionPlans-speed p:nth-child(2) {
  color: #f26a21;
  font-size: 2rem;
  font-weight: 600;
}

.subscriptionPlans-speed p:nth-child(3) {
  font-family: "PoppinsMed", sans-serif;
  display: flex;
  height: 50px;
  align-items: flex-end;
  padding-bottom: 8px;
}

.similar-features-container {
  display: flex;
  flex-direction: column;
}

.similar-features-container .title {
  text-align: center;
  text-transform: uppercase;
  font-family: 'PoppinsMed', sans-serif;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.similiar-features {
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

.similiar-features p {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .upgradedPlans-container,
  .plans {
    width: 100%; 
    height: auto;
    margin: 10px 0; 
  }

  .subscriptionPlans-details {
    height: auto;

  }
}

@media (min-width: 1024px) {
  .features-container {
    justify-content: space-evenly;
  }

  
  .similar-features {
    flex: 0 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .pricing-container {
    flex-wrap: wrap;
  }

  .features-container {
    justify-content: space-between;
  }
  
  .similar-features {
    flex: 0 0 45%;
  }
}

@media (max-width: 767px) {
  .pricing-box {
    width: calc(50% - 10px);
  }

  .similar-features {
    flex: 0 0 100%;
  }
}

@media (max-width: 376px) {
  .features-container {
    justify-content: flex-start;
  }

  .similar-features {
    width: 100%;
  }
}