.form-control {
  display: block;
  width: 100%;
  height: calc(2.1em + 0.75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffff !important;
  background-clip: padding-box;
  border: 1px solid #8888;
  border-radius: 10px;
  margin-bottom: .5rem;
  font-family: 'PoppinsReg';
  font-size: .9rem;
}

.form-control:disabled {
  opacity: 0.8;
  background-color: #ededed !important;
  color: #161617;
}

.form-control:focus,
textarea:focus {
  box-shadow: 0 0 0 0.2rem #afafaf29;
  outline: 0;
}

textarea {
  resize: none;
  width: 100%;
  display: block;
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #8888;
  border-radius: 10px;
  margin-bottom: .5rem;
  font-family: 'PoppinsReg';
  font-size: .9rem;
}

select {
  display: block;
  width: 100%;
  height: calc(2.1em + 0.75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #757575 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #8888;
  border-radius: 10px;
}

.checbox-container {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
}

.checbox-container input {
  display: none;
}

.custom-checkbox-display {
  width: 15px !important;
  height: 15px;
  border: 1px solid #888;
  background-color: white;
  border-radius: 7px;
  margin-right: 7px;
  cursor: pointer;
  padding: 8px;
}

.checbox-container span:nth-child(2) {
  font-size: .9rem;
}

.custom-checkbox:checked + .custom-checkbox-display {
  background-color: #f26531;
  border-color: #f26531;
  box-shadow: inset 0px 0px 0px 2px #f7f2f2;
}

